html, body, #root, .App {
  width: 100%;
  height: 100%;
}

.Sidebar {
  padding:20px;
  color: white;
  background: #272822;
  flex-shrink: 0;
  width: 250px;
}

.MenuItem {
  cursor: pointer;
  margin:8px;
  color:white;
}

.MenuItem a {
  color: white;
}

.Codebar {
  font-size: 12px;
  background: #272822;
  height: 100%;
  width: 550px;
  flex-shrink: 0;
  white-space: pre-wrap
}

.MainContent {
  background: #f3f3f3;  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.MainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.Header {
  padding:20px;
  background: white;
  border-bottom:1px solid #e0dfdf;
  margin-bottom:20px;
}


p {
  padding:0px 20px;
}

.BlueSection {
  padding:20px;
  background: #c6e6ff;
  margin-bottom:20px;
}

.Subtitle {
  padding:20px;
  border-bottom:1px solid #e0dfdf;
  margin-bottom:20px;
}

.CodeLangSelect, .CodeLangSelect:focus {
  background: #272822;
  color: white;
  border: none;
}